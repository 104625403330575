import { Provider } from 'react-redux';
import {
 BrowserRouter, Routes, Route, Navigate
} from 'react-router-dom';
import store from './store';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Footer2 from './components/footer/footer2';
import Footer3 from './components/footer/footer3';
import HomePage from './pages/home';
import AboutPage from './pages/about';
import Error404Page from './pages/error/Error404Page';
import GalleryPage from './pages/gallery';
import ContactPage from './pages/contact';
import ReservationPage from './pages/reservation';
import MenuPage from './pages/order/menu/menuPage';
import MenuPage2 from './pages/order/menu/menuPage2';
import Login from './pages/auth/login';
import ForgetPassword from './pages/auth/forgetPassword';
import routes from './util/routes';
import ResetPassword from './pages/auth/resetPassword';
import Register from './pages/auth/register';
import OrderConfirmation from './pages/order/comfirmation/orderConfirmationPage';
import { theme } from './components/util/theme';
import OrderSuccess from './pages/order/success/orderSuccess';
import OrderHistoryPage from './pages/order/orderHistory';
import UserProfile from './pages/order/userProfile';
import Awards from './pages/awards';
import Privacy from './pages/privacy';
import Points from './pages/points';
import Terms from './pages/conditions';
import AllergyInformation from './pages/allergy';
import { SnackbarProvider } from 'notistack';
import { BASE_URL_ADMIN } from './util/constants';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, CssBaseline } from '@mui/material';
import ErrorBoundary from './pages/error/ErrorBoundry';
import RedirectTo from './components/util/RedirectTo';


function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider dense preventDuplicate>

            <CssBaseline />
            <div className="App">
              <ErrorBoundary>
                <Header />
                <Routes>
                  <Route path={routes.home} exact element={<HomePage />} />
                  <Route path={routes.order} exact element={<MenuPage2 />} />
                  <Route path={routes.orderConfirmation} element={<OrderConfirmation />} />
                  <Route path={routes.orderSuccess} element={<OrderSuccess />} />

                  <Route path={routes.gallery} element={<GalleryPage />} />
                  <Route path={routes.about} element={<AboutPage />} />
                  <Route path={routes.reservation} element={<ReservationPage />} />
                  <Route path={routes.contact} element={<ContactPage />} />

                  <Route path={routes.signup} element={<Register />} />
                  <Route path={routes.login} element={<Login />} />
                  <Route path={routes.forgetPassword} element={<ForgetPassword />} />
                  <Route path={`${routes.resetPassword}/:token`} element={<ResetPassword />} />

                  <Route path={routes.orderHistory} element={<OrderHistoryPage />} />
                  <Route path={routes.userProfile} element={<UserProfile />} />
                  <Route path={routes.awards} element={<Awards />} />

                  <Route path={routes.privacyPolicy} element={<Privacy />} />
                  <Route path={routes.points} element={<Points />} />

                  <Route
                    path={routes.manage}
                    element={
                      <RedirectTo fullUrl={BASE_URL_ADMIN} />
                    }
                  />

                  <Route path={routes.termsConditions} element={<Terms />} />
                  <Route path={routes.allergyInformation} element={<AllergyInformation />} />
                  <Route path="*" element={<Error404Page />} />
                </Routes>
                <Footer />
              </ErrorBoundary>
            </div>

          </SnackbarProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  );
}


export default App;

